import * as React from 'react';
type IconProps = React.ComponentProps<'svg'>;

export function ListCheckIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 13l4 4L19 7"
      />
    </svg>
  );
}
export function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-4 h-4 inline-block align-text-top"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
      />
    </svg>
  );
}

export function HouseIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"
      ></path>
      <path
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256"
      ></path>
      <path
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M400 179L400 64 352 64 352 133"
      ></path>
    </svg>
  );
}

export function MenuIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="32"
        d="M80 160L432 160"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="32"
        d="M80 256L432 256"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="32"
        d="M80 352L432 352"
      ></path>
    </svg>
  );
}
export function LoginIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill={props.color}
        d="M390.42 75.28a10.45 10.45 0 01-5.32-1.44C340.72 50.08 302.35 40 256.35 40c-45.77 0-89.23 11.28-128.76 33.84C122 77 115.11 74.8 111.87 69a12.4 12.4 0 014.63-16.32A281.81 281.81 0 01256.35 16c49.23 0 92.23 11.28 139.39 36.48a12 12 0 014.85 16.08 11.3 11.3 0 01-10.17 6.72zm-330.79 126a11.73 11.73 0 01-6.7-2.16 12.26 12.26 0 01-2.78-16.8c22.89-33.6 52-60 86.69-78.48 72.58-38.84 165.51-39.12 238.32-.24 34.68 18.48 63.8 44.64 86.69 78a12.29 12.29 0 01-2.78 16.8 11.26 11.26 0 01-16.18-2.88c-20.8-30.24-47.15-54-78.36-70.56-66.34-35.28-151.18-35.28-217.29.24-31.44 16.8-57.79 40.8-78.59 71a10 10 0 01-9.02 5.08zM204.1 491a10.66 10.66 0 01-8.09-3.6C175.9 466.48 165 453 149.55 424c-16-29.52-24.27-65.52-24.27-104.16 0-71.28 58.71-129.36 130.84-129.36S387 248.56 387 319.84a11.56 11.56 0 11-23.11 0c0-58.08-48.32-105.36-107.72-105.36S148.4 261.76 148.4 319.84c0 34.56 7.39 66.48 21.49 92.4 14.8 27.6 25 39.36 42.77 58.08a12.67 12.67 0 010 17 12.44 12.44 0 01-8.56 3.68zm165.75-44.4c-27.51 0-51.78-7.2-71.66-21.36a129.1 129.1 0 01-55-105.36 11.57 11.57 0 1123.12 0 104.28 104.28 0 0044.84 85.44c16.41 11.52 35.6 17 58.72 17a147.41 147.41 0 0024-2.4c6.24-1.2 12.25 3.12 13.4 9.84a11.92 11.92 0 01-9.47 13.92 152.28 152.28 0 01-27.95 2.88zM323.38 496a13 13 0 01-3-.48c-36.76-10.56-60.8-24.72-86-50.4-32.37-33.36-50.16-77.76-50.16-125.28 0-38.88 31.9-70.56 71.19-70.56s71.2 31.68 71.2 70.56c0 25.68 21.5 46.56 48.08 46.56s48.08-20.88 48.08-46.56c0-90.48-75.13-163.92-167.59-163.92-65.65 0-125.75 37.92-152.79 96.72-9 19.44-13.64 42.24-13.64 67.2 0 18.72 1.61 48.24 15.48 86.64 2.32 6.24-.69 13.2-6.7 15.36a11.34 11.34 0 01-14.79-7 276.39 276.39 0 01-16.88-95c0-28.8 5.32-55 15.72-77.76 30.75-67 98.94-110.4 173.6-110.4 105.18 0 190.71 84.24 190.71 187.92 0 38.88-31.9 70.56-71.2 70.56s-71.2-31.68-71.2-70.56c.01-25.68-21.49-46.6-48.07-46.6s-48.08 20.88-48.08 46.56c0 41 15.26 79.44 43.23 108.24 22 22.56 43 35 75.59 44.4 6.24 1.68 9.71 8.4 8.09 14.64a11.39 11.39 0 01-10.87 9.16z"
      ></path>
    </svg>
  );
}
export function UserIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        stroke={props.color}
        d="M258.9,48C141.92,46.42,46.42,141.92,48,258.9,49.56,371.09,140.91,462.44,253.1,464c117,1.6,212.48-93.9,210.88-210.88C462.44,140.91,371.09,49.56,258.9,48ZM385.32,375.25a4,4,0,0,1-6.14-.32,124.27,124.27,0,0,0-32.35-29.59C321.37,329,289.11,320,256,320s-65.37,9-90.83,25.34a124.24,124.24,0,0,0-32.35,29.58,4,4,0,0,1-6.14.32A175.32,175.32,0,0,1,80,259C78.37,161.69,158.22,80.24,255.57,80S432,158.81,432,256A175.32,175.32,0,0,1,385.32,375.25Z"
      />
      <path
        stroke={props.color}
        d="M256,144c-19.72,0-37.55,7.39-50.22,20.82s-19,32-17.57,51.93C191.11,256,221.52,288,256,288s64.83-32,67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39,151.44,275.59,144,256,144Z"
      />
    </svg>
  );
}

export function MessageIcon(props: IconProps) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 2.513a1 1 0 011 1V11.5a1 1 0 01-1 1H5.37a1 1 0 00-.65.24l-1.57 1.345a1 1 0 01-1.65-.76V3.514a1 1 0 011-1h11z"
        stroke="currentColor"
      />
    </svg>
  );
}

export function ChevronLeftIcon(props: IconProps) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 4.5L6.35355 7.64645C6.15829 7.84171 6.15829 8.15829 6.35355 8.35355L9.5 11.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-4 h-4 inline-block"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 8l4 4m0 0l-4 4m4-4H3"
      />
    </svg>
  );
}

export function ChevronRightIcon(props: IconProps) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 11.5l3.146-3.146a.5.5 0 000-.708L6.5 4.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EditIcon(props: IconProps) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.333 2A1.886 1.886 0 0114 4.667l-9 9-3.667 1 1-3.667 9-9z"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TrashIcon(props: IconProps) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4h12M5.333 4V2.667a1.333 1.333 0 011.334-1.334h2.666a1.333 1.333 0 011.334 1.334V4m2 0v9.333a1.333 1.333 0 01-1.334 1.334H4.667a1.334 1.334 0 01-1.334-1.334V4h9.334z"
        stroke="currentColor"
        strokeWidth={1.41667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ListIcon(props: IconProps) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.333 4H14M5.333 8H14M5.333 12H14M2 4h.007M2 8h.007M2 12h.007"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LinkIcon(props: IconProps) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.667 8.667a3.333 3.333 0 005.026.36l2-2A3.334 3.334 0 008.98 2.313l-1.147 1.14"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.333 7.333a3.334 3.334 0 00-5.026-.36l-2 2a3.333 3.333 0 004.713 4.714l1.14-1.14"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Logo(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="932.613"
      height="223.141"
      version="1.1"
      viewBox="0 0 932.613 223.141"
      xmlSpace="preserve"
      {...props}
    >
      <defs>
        <clipPath id="clipPath16" clipPathUnits="userSpaceOnUse">
          <path d="M0 400h1000V0H0z"></path>
        </clipPath>
        <clipPath id="clipPath56" clipPathUnits="userSpaceOnUse">
          <path d="M0 400h1000V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -86.973 402.958)">
        <g>
          <g clipPath="url(#clipPath16)">
            <g transform="translate(246.039 162.023)">
              <path
                fill="#160123"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l6.077 65.433c.216 3.255 2.17 4.992 5.425 4.992h7.813c3.039 0 4.884-1.302 6.077-4.124L44.707 19.1l19.424 47.201c1.086 2.822 3.038 4.124 5.969 4.124h7.921c3.255 0 5.1-1.737 5.426-4.992L89.523 0c.326-3.581-1.519-5.534-4.991-5.534h-6.619c-3.365 0-5.209 1.737-5.535 4.992L68.906 40.91 52.195-.542c-1.085-2.821-3.038-4.124-5.968-4.124h-2.93c-2.93 0-4.883 1.303-5.968 4.124L20.617 40.91 17.037-.542c-.217-3.255-2.17-4.992-5.426-4.992H4.992C1.52-5.534-.435-3.581 0 0"
              ></path>
            </g>
            <g transform="translate(348.358 161.698)">
              <path
                fill="#160123"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0v45.358c0 3.365 1.845 5.208 5.208 5.208h6.294c3.364 0 5.318-1.843 5.318-5.208V0c0-3.364-1.954-5.209-5.318-5.209H5.208C1.845-5.209 0-3.364 0 0m-1.737 65.867c0 4.992 3.582 8.789 10.092 8.789 6.403 0 10.092-3.797 10.092-8.789 0-4.883-3.906-8.681-10.092-8.681-6.185 0-10.092 3.798-10.092 8.681"
              ></path>
            </g>
            <g transform="translate(378.738 161.698)">
              <path
                fill="#160123"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0v45.358c0 3.365 1.845 5.208 5.209 5.208h6.402c3.364 0 5.209-1.843 5.209-5.208v-3.689c2.712 6.403 8.68 10.092 16.928 10.092 14.975 0 20.4-11.72 20.4-24.85V0c0-3.364-1.953-5.209-5.317-5.209h-6.293c-3.365 0-5.209 1.845-5.209 5.209v25.717c0 7.813-3.039 12.371-8.79 12.371-5.75 0-11.719-4.448-11.719-14.866V0c0-3.364-1.845-5.209-5.209-5.209H5.209C1.845-5.209 0-3.364 0 0"
              ></path>
            </g>
            <g transform="translate(487.246 184.378)">
              <path
                fill="#160123"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-.108 8.897-5.751 15.625-14.324 15.625-8.68 0-13.889-7.053-13.889-15.625 0-8.682 5.209-15.626 13.889-15.626C-5.751-15.626-.108-8.897 0 0m-45.033 0c0 16.386 11.177 29.081 26.911 29.081 7.27 0 13.781-2.822 17.797-9.115v25.392c0 3.363 1.953 5.208 5.317 5.208h6.294c3.363 0 5.208-1.845 5.208-5.208V-22.68c0-3.364-1.845-5.209-5.208-5.209H4.992c-3.364 0-5.317 1.845-5.317 5.209v2.605c-4.016-6.186-10.527-9.007-17.797-9.007-15.734 0-26.911 12.588-26.911 29.082"
              ></path>
            </g>
            <g transform="translate(519.903 161.698)">
              <path
                fill="#160123"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0v65.542c0 3.364 1.953 5.208 5.317 5.208h6.728c3.364 0 5.209-1.844 5.209-5.208V9.332h29.081c3.364 0 5.317-1.845 5.317-5.209V0c0-3.364-1.953-5.209-5.317-5.209H5.317C1.953-5.209 0-3.364 0 0"
              ></path>
            </g>
            <g transform="translate(621.574 184.378)">
              <path
                fill="#160123"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 8.897-5.643 15.625-14.215 15.625-8.79 0-13.89-7.053-13.89-15.625 0-8.682 5.1-15.626 13.89-15.626C-5.643-15.626 0-8.897 0 0m-44.924 0c0 16.386 11.177 29.081 26.802 29.081 7.379 0 13.89-2.822 17.905-9.115v2.713c0 3.364 1.845 5.207 5.208 5.207h6.404c3.363 0 5.208-1.843 5.208-5.207V-22.68c0-3.364-1.845-5.209-5.208-5.209H4.991c-3.363 0-5.208 1.845-5.208 5.209v2.605c-4.015-6.186-10.526-9.007-17.905-9.007-15.625 0-26.802 12.588-26.802 29.082"
              ></path>
            </g>
            <g transform="translate(696.332 184.378)">
              <path
                fill="#160123"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 8.572-5.208 15.625-13.89 15.625-8.679 0-14.214-6.728-14.323-15.625.109-8.897 5.644-15.626 14.323-15.626C-5.208-15.626 0-8.682 0 0m-27.887-20.075v-2.605c0-3.364-1.954-5.209-5.317-5.209h-6.294c-3.364 0-5.208 1.845-5.208 5.209v68.038c0 3.363 1.844 5.208 5.208 5.208h6.294c3.363 0 5.317-1.845 5.317-5.208V19.966c4.015 6.293 10.526 9.115 17.796 9.115C5.645 29.081 16.82 16.386 16.82 0c0-16.494-11.175-29.082-26.911-29.082-7.27 0-13.781 2.821-17.796 9.007"
              ></path>
            </g>
            <g transform="translate(720.633 166.474)">
              <path
                fill="#160123"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-1.628 3.255.107 5.643 3.472 6.511l3.364.759c2.93.759 4.232-.652 6.511-2.93 1.953-2.171 4.882-3.255 8.356-3.255 4.231 0 7.161 1.952 7.161 4.883 0 2.386-1.628 3.58-5.209 4.883l-6.837 2.386c-5.75 1.846-15.733 6.077-15.733 16.17 0 10.417 8.681 17.578 20.726 17.578 7.162 0 13.672-2.278 17.796-8.464 2.169-3.146.541-5.968-3.039-6.835l-3.038-.76c-2.822-.76-4.341.217-6.294 2.062-1.518 1.518-3.473 2.061-5.425 2.061-3.472 0-5.643-2.17-5.643-4.776 0-2.386 2.171-3.58 5.101-4.555l6.945-2.606c11.501-3.798 15.624-9.983 15.843-16.819 0-11.719-10.419-17.471-22.463-17.471C11.719-11.178 3.689-7.488 0 0"
              ></path>
            </g>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath56)">
            <g transform="translate(130.358 232.354)">
              <path
                fill="#ff8b00"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0c9.156 0 16.578 7.422 16.578 16.578 0 9.156-7.422 16.578-16.578 16.578-9.156 0-16.578-7.422-16.578-16.578C-16.578 7.422-9.156 0 0 0"
              ></path>
            </g>
            <g transform="translate(157.594 264.326)">
              <path
                fill="#8657f3"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0c6.54 0 11.841 5.301 11.841 11.841 0 6.54-5.301 11.842-11.841 11.842-6.54 0-11.842-5.302-11.842-11.842C-11.842 5.301-6.54 0 0 0"
              ></path>
            </g>
            <g transform="translate(122.07 285.64)">
              <path
                fill="#fc3d80"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0a8.289 8.289 0 110 16.578A8.289 8.289 0 110 0"
              ></path>
            </g>
            <g transform="translate(135.479 184.34)">
              <path
                fill="#ff8b00"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0l-46.066-45.389c-5.59-5.508-14.587-5.441-20.095.149a14.207 14.207 0 00-4.088 9.973V55.51c0 7.848 6.362 14.21 14.21 14.21 3.732 0 7.314-1.469 9.973-4.088L0 20.244C5.59 14.735 5.657 5.738.149.148L0 0"
              ></path>
            </g>
            <g transform="translate(130.358 179.294)">
              <path
                fill="#fc3d80"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0l-20.242-19.944 10.12-10.271c5.508-5.59 14.505-5.656 20.095-.148l.149.148 10.119 10.272z"
              ></path>
            </g>
            <g transform="translate(181.276 254.06)">
              <path
                fill="#8657f3"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0a14.212 14.212 0 01-9.973-4.087l-40.945-40.344 5.121-5.045c5.59-5.508 5.656-14.505.149-20.095l-.149-.149-5.121-5.046 40.945-40.342c5.59-5.509 14.587-5.442 20.095.148a14.207 14.207 0 014.088 9.974v90.777C14.21-6.362 7.848 0 0 0"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function LogoIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="533.333"
      height="533.333"
      version="1.1"
      viewBox="0 0 533.333 533.333"
      xmlSpace="preserve"
      {...props}
    >
      <defs>
        <clipPath id="clipPath16" clipPathUnits="userSpaceOnUse">
          <path d="M0 400h400V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 533.333)">
        <g>
          <g clipPath="url(#clipPath16)">
            <g transform="translate(199.123 211.586)">
              <path
                fill="#ff8b00"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0c9.156 0 16.578 7.422 16.578 16.578 0 9.156-7.422 16.578-16.578 16.578-9.156 0-16.578-7.422-16.578-16.578C-16.578 7.422-9.156 0 0 0"
              ></path>
            </g>
            <g transform="translate(226.358 243.558)">
              <path
                fill="#8657f3"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0c6.54 0 11.842 5.302 11.842 11.842 0 6.539-5.302 11.841-11.842 11.841-6.54 0-11.842-5.302-11.842-11.841C-11.842 5.302-6.54 0 0 0"
              ></path>
            </g>
            <g transform="translate(190.834 264.873)">
              <path
                fill="#fc3d80"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0a8.289 8.289 0 110 16.578A8.289 8.289 0 110 0"
              ></path>
            </g>
            <g transform="translate(204.244 163.571)">
              <path
                fill="#ff8b00"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0l-46.066-45.389c-5.591-5.507-14.587-5.44-20.095.15a14.206 14.206 0 00-4.088 9.972v90.778c0 7.848 6.362 14.21 14.209 14.21 3.733 0 7.315-1.469 9.974-4.088L0 20.244C5.59 14.736 5.656 5.739.148.149L0 0"
              ></path>
            </g>
            <g transform="translate(199.123 158.526)">
              <path
                fill="#fc3d80"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0l-20.242-19.944 10.12-10.271c5.508-5.59 14.505-5.656 20.095-.148l.149.148 10.119 10.271z"
              ></path>
            </g>
            <g transform="translate(250.041 233.292)">
              <path
                fill="#8657f3"
                fillOpacity="1"
                fillRule="evenodd"
                stroke="none"
                d="M0 0a14.21 14.21 0 01-9.973-4.088l-40.945-40.343 5.121-5.046c5.59-5.507 5.656-14.504.149-20.094l-.149-.15-5.121-5.045 40.945-40.342c5.59-5.509 14.587-5.442 20.095.148a14.207 14.207 0 014.088 9.973v90.778C14.21-6.362 7.848 0 0 0"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function CalendarIcon(props: IconProps) {
  return (
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-500 dark:text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function ErrorIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
    </svg>
  );
}
